<template>
  <div class="container">
    <van-nav-bar title="供应商订单列表" />
    <van-tabs v-model="activeIndex" @click="tabSwitch">
      <van-tab v-for="(item, key) in tabList" :key="key" :title="item.title">
      </van-tab>
    </van-tabs>

    <div class="list-wrap">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <van-cell v-for="item in list" @click="info(item.id, item.page)">
            <template slot="default">
              <div class="item-wrap">
                <div class="item-header">
                  <img
                    src="https://static.dingtalk.com/media/lALOnahFD80CgM0CgA_640_640.png_450x10000q90.jpg"
                    alt=""
                  />
                  <h3
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span> 产品品名：{{ item.productName }}</span>
                    <span
                      v-if="item.daysExpired && item.daysExpired > 0"
                      style="color: red"
                    >
                      已逾期{{ item.daysExpired }}天
                    </span>
                  </h3>
                </div>

                <div class="item-block">
                  <p>合同号：{{ item.contractNumber }}</p>
                  <p>应交数量：{{ item.quantityPurchased }}</p>
                  <p>已交数量：{{ item.quantityDelivered }}</p>
                  <p>预到货日：{{ item.expectedArrivalTime }}</p>
                  <p v-if="item.supplierRemark">
                    反馈：{{ item.supplierRemark }}
                  </p>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>

    <div>
      <!-- 弹出框 -->
      <el-dialog
        title="订单详情"
        :visible.sync="dialogVisible"
        width="80%"
        @close="handleClose"
      >
        <!-- 使用 iframe 来嵌套页面 -->
        <iframe
          v-if="iframeVisible"
          :src="iframeSrc"
          width="100%"
          height="500"
          frameborder="0"
        ></iframe>

        <!-- 弹出框底部的操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/erpVendorIntransitOrders'
export default {
  name: 'process',

  data() {
    return {
      id: 0, // 详情id
      oldPage: 1, // 详情所在页码
      dialogVisible: false, // 控制弹出框的显示状态
      iframeVisible: true, // 控制 iframe 的显示和销毁

      list: [],
      loading: false,
      loadingData: false,
      finished: false,
      refreshing: false,

      pageNo: 1,
      pageSize: 10,
      pages: 1,
      searchObj: {},
      activeIndex: 0,
      tabList: [{ title: '全部' }, { title: '三天内' }, { title: '已到期' }],
    }
  },

  computed: {
    iframeSrc() {
      const timestamp = new Date().getTime() // 获取当前时间戳
      // 使用 window.location.origin 生成完整路径
      const page = this.oldPage // 计算当前页码
      console.log(
        window.location.origin +
          `/#/supplierShow/${this.id}?t=${timestamp}&page=${page}`
      )
      return (
        window.location.origin +
        `/#/supplierShow/${this.id}?t=${timestamp}&page=${page}`
      )
    },
  },

  created() {
    this.activeIndex = parseInt(this.$route.params.activeIndex)
    this.onLoad()
  },

  mounted() {
    window.addEventListener('message', this.handleMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },

  methods: {
    // 监听详情页的参数
    handleMessage(event) {
      console.log('监听参数:' + event)
      if (event.data.action === 'onLoad') {
        const page = event.data.page // 获取页码
        this.refreshList(page)
      }
    },
    // 刷新某一页的数据
    refreshList(page) {
      if (this.activeIndex === 0) {
        this.fetchPageInSupplierAll(page)
      }
      if (this.activeIndex === 1) {
        this.fetchPageInSupplierVerySoon(page)
      }
      if (this.activeIndex === 2) {
        this.fetchPageInSupplierExpired(page)
      }

      // 删除从下标 i 开始的所有数据
      this.list.splice(page * this.pageSize)
    },

    tabSwitch() {
      //tab切换，重新初始化数据
      this.list = []
      this.pageNo = 1
      this.finished = false

      //tabs切换时，如果之前的tab已经滚动到底部（list加载到底部），直接点击其他的tab，将再触发一次onload事件。
      //可能调用2次onLoad()方法，延迟执行，通过时间差解决问题
      setTimeout(() => {
        if (!this.finished) {
          this.onLoad()
        }
      }, 500)
    },

    onLoad() {
      // 如果正在加载，直接返回
      if (this.loadingData) return

      this.loadingData = true // 标记为加载状态
      if (this.activeIndex === 0) {
        this.pageInSupplierAll()
      }
      if (this.activeIndex === 1) {
        this.pageInSupplierVerySoon()
      }
      if (this.activeIndex === 2) {
        this.pageInSupplierExpired()
      }
      this.loadingData = false
    },

    resetData() {
      this.searchObj = {}
      this.onRefresh()
    },

    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.list = []

      this.pageNo = 1
      // 重新加载数据
      this.onLoad()
    },

    pageInSupplierAll() {
      api
        .pageInSupplierAll(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
      this.loadingData = false // 请求失败后清除加载状态
    },
    fetchPageInSupplierAll(page) {
      api
        .pageInSupplierAll(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    pageInSupplierVerySoon() {
      api
        .pageInSupplierVerySoon(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            // item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
      this.loadingData = false // 请求失败后清除加载状态
    },
    fetchPageInSupplierVerySoon(page) {
      api
        .pageInSupplierVerySoon(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    pageInSupplierExpired() {
      api
        .pageInSupplierExpired(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
      this.loadingData = false // 请求失败后清除加载状态
    },

    fetchPageInSupplierExpired(page) {
      api
        .pageInSupplierExpired(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    info(id, oldPage) {
      this.id = id
      this.oldPage = oldPage
      this.dialogVisible = true

      this.iframeVisible = false // 销毁 iframe
      this.$nextTick(() => {
        this.iframeVisible = true // 重新显示 iframe
      })
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.id = 0
      this.oldPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  background: #1d1e20;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
.container {
  padding-bottom: 50px;
  .list-wrap {
    margin-top: 4px;
    border-top: 1px solid #ebedf0;
  }
  .item-wrap {
    font-size: 12px;
    color: #a7a8a9;
    .item-header {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 4px;
      }
      h3 {
        flex: 1;
        font-size: 15px;
        color: #000;
        padding: 0;
        margin: 0;
      }
    }

    .item-block {
      padding: 4px 0;
      border: 1px solid #ddd; /* 添加边框，提升视觉分隔 */
      font-size: 14px;
      p {
        padding: 0;
        margin: 5px 0;
        color: #000;
        line-height: 20px;
      }
    }

    .item-status {
      .pass {
        color: #4cb971;
      }
      .refused {
        color: #eb8473;
      }
    }
  }
}
</style>
